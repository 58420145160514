/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapWithProvider from './wrap-with-provider';
export const wrapRootElement = wrapWithProvider


// Path analytics
export function onRouteUpdate({ location, prevLocation }) {
  let capitalizeFirstLetter = function(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // Dont fire on first route
  if (prevLocation == null) return
  
  // Check if datalayer is available
  if (window.dataLayer) {
    let newPathName = location.pathname;
    let previousPathName = prevLocation ? prevLocation.pathname : null;
    let PageTitle = newPathName.replace(/\//g, '');
    let fullTitle = "";
    if (PageTitle === "") {
      fullTitle = "Homepage";
    } else {
      fullTitle = PageTitle;
    }
    setTimeout(() => {    
      let pathTitle = document.title;

      // Push routechange 
      window.dataLayer.push({
        "event" : "custom.route.change",
        "route" : {
          "title" : pathTitle,
          "current" : newPathName,
          "previous" : previousPathName
        },
        "content" : {
          "category" : capitalizeFirstLetter(fullTitle),
          "type" : 'Page',
          "version" : '1.01'
        }
      });
    }, 500);
  }
}