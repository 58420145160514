// Note this is a really minimal redux setup for small things,
// Expand and put into folders when it gets bigger

import { createStore as reduxCreateStore } from "redux"

const initialState = { 
  menuOpen: false,
  browser: '',
  subjectFilters: [],
  levelFilters: [],
  availableLevels: [],
  availableSubjects: [],
  searchTerm: '',
  searchTermActive: false,
  numberOfResults: 0,
  lightboxURL: '',
  lightbox: false
}

const reducer = (state, action) => {
  let subjectFilters = state.subjectFilters.slice();
  let levelFilters = state.levelFilters.slice();

  switch (action.type) {
    case `HANDLE_MENU_OPEN`:
      return Object.assign({}, state, {
        menuOpen: action.openClose,
      })
    case `SET_BROWSER`:
      return Object.assign({}, state, {
        browser: action.browser,
      })
    case `CLEAR_ALL_SEARCH`:
      return Object.assign({}, state, {
        subjectFilters: [],
        levelFilters: [],
        searchTerm: '',
        searchTermActive: false,
      })
    case `SET_ACTIVE_SUBJECT_FILTER`:
      subjectFilters.push(action.filter)
      return Object.assign({}, state, {
        subjectFilters: subjectFilters,
      })
    case `REMOVE_ACTIVE_SUBJECT_FILTER`:
      const filteredSubjectArray =  subjectFilters.filter(filter => filter !== action.filter);
      return Object.assign({}, state, {
        subjectFilters: filteredSubjectArray,
      })
    case `SET_ACTIVE_LEVEL_FILTER`:
      levelFilters.push(action.filter)
      return Object.assign({}, state, {
        levelFilters: levelFilters,
      })
    case `REMOVE_ACTIVE_LEVEL_FILTER`:
      const filteredLevelArray =  levelFilters.filter(filter => filter !== action.filter);
      return Object.assign({}, state, {
        levelFilters: filteredLevelArray,
      })
    case `SET_AVAILABLE_LEVELS`:
      return Object.assign({}, state, {
        availableLevels: action.availableLevels,
      })
    case `SET_AVAILABLE_SUBJECTS`:
      return Object.assign({}, state, {
        availableSubjects: action.availableSubjects,
      })
    case `SET_SEARCH_TERM`:
      return Object.assign({}, state, {
        searchTerm: action.searchTerm,
      })
    case `SET_SEARCH_ACTIVE`:
      return Object.assign({}, state, {
        searchTermActive: action.searchTermActive,
      })
    case `SET_NUMBER_OF_RESULTS`:
      return Object.assign({}, state, {
        numberOfResults: action.numberOfResults,
      })
    case `SET_LIGHTBOX_URL`:
      return Object.assign({}, state, {
        lightboxURL: action.URL,
      })
    case `SET_LIGHTBOX`:
      return Object.assign({}, state, {
        lightbox: action.onOff,
      })
    default:
      break 
  }
  return state
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore