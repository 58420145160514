// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-templates-staticpage-template-tsx": () => import("./../src/components/templates/staticpage-template.tsx" /* webpackChunkName: "component---src-components-templates-staticpage-template-tsx" */),
  "component---src-components-templates-sequence-template-tsx": () => import("./../src/components/templates/sequence-template.tsx" /* webpackChunkName: "component---src-components-templates-sequence-template-tsx" */),
  "component---src-components-templates-sequence-stage-index-template-tsx": () => import("./../src/components/templates/sequence-stage-index-template.tsx" /* webpackChunkName: "component---src-components-templates-sequence-stage-index-template-tsx" */),
  "component---src-components-templates-sequence-stage-template-tsx": () => import("./../src/components/templates/sequence-stage-template.tsx" /* webpackChunkName: "component---src-components-templates-sequence-stage-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learning-areas-tsx": () => import("./../src/pages/learning-areas.tsx" /* webpackChunkName: "component---src-pages-learning-areas-tsx" */)
}

